import * as React from 'react';
import * as styles from './Logo.module.scss';

const Logo: React.FunctionComponent<React.SVGAttributes<SVGElement>> = (
  props,
) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 2758 1008"
    xmlSpace="preserve"
    {...props}
    className={`${props.className} ${styles.logo}`}
  >
    <title>Klink Made</title>
    <path
      className="cls-1"
      d="M1124.09,710.66c.1,0-51.88,61.18-160.95,61.18-190.7,0-288.89-123.29-396.31-332.74L478.91,453c10.92-4.32,20.71-8.5,28.65-12.32,51.87-24.94,96-51.88,132.68-99.76,22.65-29.53,41-74,50.43-112.51,6.47-26.34,12.35-49.54,21.66-73.82,10.49-27.38,29.56-50.88,49.51-62.41,20.48-11.83,56-19.62,89.17-14.7C819.18,51.2,765,21.18,684.8,21.18c-81.71,0-152.72,32.49-189.19,100.33-26,48.35-35.15,108.51-46.2,178-13,81.89-29.05,114.15-70.75,137.55a135.6,135.6,0,0,1-37.19,14.15l.13-202.65c0-29.7,1.2-50.5,2-62,1.9-28.7,21.9-39.7,49.1-47.7v-.3H20.5v.3c27.2,8,47.2,19,49,47.7.8,11.5,2,32.3,2,62v312c0,29.7-1.2,50.5-2,62-1.9,28.7-21.9,39.7-49,47.7v.3h372v-.3c-27.2-8-47.2-19-49.1-47.7-.8-11.5-2-32.3-2-62l0-64.08c10.07-2.22,22.31-5.41,35.66-9.23,91.31,288.59,243.6,497.38,519.53,497.38,165.61,0,253.42-90.06,254.32-177.57C1151.35,768.3,1142.83,733.11,1124.09,710.66Z"
    />
    <path
      className="cls-1"
      d="M829.5,260.3V503.7c0,27.5,1.3,51.8,4.2,73.5,9.8,74.5,58.8,114.4,128.8,114.4,51.7,0,84.15-19.29,96.88-50.36-19.35-10.18-33.11-27-37.69-50.93-2.73-14.24-4.19-38.41-4.19-58.61V304.3c0-94.2-48.4-177.1-153-177.1-31.15,0-61.17,8.74-77.2,21.4C823.19,172,829.5,203.3,829.5,260.3Z"
    />
    <path
      className="cls-1"
      d="M1284.6,233.1c59.6-16.5,98.4-64.4,86.6-106.9s-69.7-63.6-129.3-47-98.4,64.4-86.6,106.9S1225,249.7,1284.6,233.1Z"
    />
    <path
      className="cls-1"
      d="M1415.6,670.3c-27.2-8-47.2-19-49.1-47.7-.8-11.5-2-32.3-2-62v-300s-45,7-110,28c-64.7,20.9-119.1,50.5-119.1,50.5v.3c27.2,8,47.2,19,49,47.7.8,11.5,2.1,38.35,2.1,61.9V560.7c0,29.7-1.2,50.5-2,62-1.9,28.7-21.9,39.7-49.1,47.7v.3h280Z"
    />
    <path
      className="cls-1"
      d="M2008.7,409.4c-21.19-100.29-95.37-130.2-165.3-130.2-54.2,0-97.3,7.6-139.9,34.6V260.9l.1-.1h-.1c-3.1.5-47.2,7.7-109.8,28-64.7,20.9-119.1,50.5-119.1,50.5v.3c27.2,8,47.2,19,49.1,47.7.8,11.5,2,62,2,62V560.8c0,29.7-1.2,50.5-2,62-1.9,28.7-21.9,39.7-49.1,47.7v.3h280v-.3c-27.2-8-47.2-19-49.1-47.7-.8-11.5-2-32.3-2-62V342.6a66.66,66.66,0,0,1,17.8-2.6c18.38,0,38.67,2,55.28,13.34,18.38,12.53,29.65,36.94,34.36,75,7.37,59.39,19.52,138.36,42.7,185.24,21.51,43.51,69.73,78.07,131.16,78.07,60.33,0,98.51-30.38,108.67-65.67-17.37-15.84-26.73-28.09-41.86-56.18C2034.82,538.56,2021.15,468.33,2008.7,409.4Z"
    />
    <path
      className="cls-1"
      d="M2718.7,534.8s-24.55,5.88-46.15,5.88c-48.1,0-92.87-21.38-137.48-78.11l-79.69,15.35h0c6.11-2.58,11.73-5.08,16.56-7.38,60.39-28.81,83.31-41.8,108.69-66.84,16.76-16.54,30.59-40.35,38.52-62.14,6.59-18.14,10.9-34.68,19.09-51.37,7.24-14.74,15.06-27.61,27-38.09,12.77-11.17,32.14-17.54,47-14.08-11.89-18.82-56.85-47.68-101.55-47.68-57.17,0-99.67,26.17-123.1,68.52-18.9,34.18-24.07,57.63-34.28,106.66-8.21,39.38-28,69.35-53.17,88.43a99.33,99.33,0,0,1-36.48,17.2V248.6c0-29.7,1.2-50.5,2-62,1.9-28.7,21.9-39.7,49.1-47.7v-.3h-280v.3c27.2,8,47.2,19,49.1,47.7.8,11.5,2,32.3,2,62v312c0,29.7-1.2,50.5-2,62-1.9,28.7-21.9,39.7-49.1,47.7v.3h280v-.3c-27.2-8-47.2-19-49.1-47.7-.8-11.5-2-32.3-2-62V510.77c8-2.18,18.74-5.6,30.64-9.69,39.75,103.5,111.61,190.52,217.1,190.52,75.49,0,117.06-65.53,117.06-112.5C2728.5,549.07,2718.7,534.8,2718.7,534.8Z"
    />

    <polygon
      className="cls-2"
      points="1545.15 949.5 1544.82 947.55 1546.27 942.48 1524.9 829.44 1520.16 820.25 1519.7 817.8 1541.4 817.8 1541.4 802.8 1506.2 802.8 1431 928.8 1355.8 802.8 1320.6 802.8 1320.6 817.8 1342.3 817.8 1341.84 820.25 1337.1 829.44 1315.73 942.48 1317.18 947.55 1316.85 949.5 1292.9 949.5 1292.9 964.5 1348.3 964.5 1348.3 949.5 1326.99 949.5 1327.43 947.55 1330.76 943.39 1351.41 834.35 1351.41 824.67 1425.2 948.2 1436.8 948.2 1510.59 824.67 1510.59 834.35 1531.24 943.39 1534.57 947.55 1535.01 949.5 1513.7 949.5 1513.7 964.5 1569.1 964.5 1569.1 949.5 1545.15 949.5"
    />
    <path
      className="cls-2"
      d="M1928,949.44l-1.19-2,.67-4.27-71.57-116.37-6.42-6.61L1848,817.8h21.48v-15h-72.2v15h21.48l-1.43,2.44-6.41,6.61-71.58,116.37.67,4.27-1.19,2-23.54.06v15h57.1v-15l-21.12-.06,1.38-2.25,3.65-2.74,15.79-25.65h122.6l15.79,25.65,3.65,2.74,1.38,2.25-21.12.06v15h57.1v-15ZM1833.4,903.8h-51.9l51.9-84.5,51.9,84.5Z"
    />
    <path
      className="cls-2"
      d="M2245.8,806.1c-12.5-2.3-41.7-3.3-54.5-3.3h-73.1v15h22.2v2l-2,5.07V942.35l2,5.07v2l-22.2,0v15h73.1c12.6,0,41.3-.9,53.6-3.1,61.2-10.9,74.1-48.3,74.1-77.7C2319,854.4,2306.3,817.2,2245.8,806.1Zm-3.6,140.5c-10,1.8-36.4,2.8-51,2.8l-39.9,0v-2l2-5.07V824.82l-2-5.07v-2h39.9c14.5,0,41.3,1.1,51.8,3,40.5,7.4,61,28.6,61,62.8C2304,918.1,2283.2,939.3,2242.2,946.6Z"
    />
    <polygon
      className="cls-2"
      points="2687 802.8 2493.4 802.8 2493.4 817.8 2515.65 817.8 2515.65 819.75 2513.65 824.82 2513.6 824.8 2513.6 942.37 2513.65 942.35 2515.65 947.42 2515.65 949.5 2493.4 949.5 2493.4 964.5 2687 964.5 2687 929.3 2672 929.3 2672 942.37 2672.05 942.35 2674.05 947.42 2674.05 949.5 2526.55 949.5 2526.55 947.42 2528.55 942.35 2528.6 942.37 2528.6 906.4 2613.9 906.4 2628.51 897.54 2619.49 885.59 2611.44 891.4 2528.6 891.4 2528.6 824.8 2528.55 824.82 2526.55 819.75 2526.55 817.8 2674.05 817.8 2674.05 819.75 2672.05 824.82 2672 824.8 2672 838 2687 838 2687 817.8 2687 802.8"
    />
  </svg>
);

export default Logo;
