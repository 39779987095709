import * as React from 'react';
import { Link } from 'gatsby';
import Wrapper from '../components/Wrapper';
import SEO from '../components/seo';
import * as styles from './resume.module.scss';
import MainMenu from '../components/MainMenu';

const Capabilities = () => (
  <ul className={styles.bulletList}>
    <li>
      <h4>HTML5</h4> Experience with creating clean, accessible, semantic markup
      with an understanding of how code translates to document outlines for
      screen readers.
    </li>
    <li>
      <h4>CSS</h4> 20 years experience creating extensible and maintainable CSS
      using a variety of techniques from the latest CSS-in-JS technologies, CSS
      Custom Properties, pre-processors, post-processors or just plain pure CSS
      <ul className={styles.compactList}>
        <li>Sass</li>
        <li>Less</li>
        <li>Stylus</li>
        <li>styled-components</li>
        <li>styled-jsx</li>
        <li>post-css</li>
      </ul>
    </li>
    <li>
      <h4>Server-side</h4> Creating and maintaining Node/Express web servers
      with APIs for retrieving content and sending communications.{' '}
      <ul className={styles.compactList}>
        <li>Linux</li>
        <li>Nginx</li>
        <li>Node</li>
        <li>Express</li>
        <li>Heroku</li>
      </ul>
    </li>
    <li>
      <h4>Front-end</h4> Component-based front-end development using React and
      Typescript with a focus on functional programming principles
      <ul className={styles.compactList}>
        <li>React</li>
        <li>Redux</li>
        <li>React-ARIA</li>
        <li>Modern DOM APIs</li>
      </ul>
    </li>
    <li>
      <h4>Site Frameworks</h4> Making websites using JAMstack composition
      principles
      <ul className={styles.compactList}>
        <li>NextJS</li>
        <li>Gatsby</li>
        <li>create-react-app</li>
        <li>Meteor</li>
      </ul>
    </li>
    <li>
      <h4>Design</h4> A graphic design background gives me a keen eye for detail
      and focus on coding that preserves visual fidelity and usability as well
      as experience with visual design tools.
      <ul className={styles.compactList}>
        <li>Photoshop</li>
        <li>Illustrator</li>
        <li>After Effects</li>
        <li>Indesign</li>
      </ul>
    </li>
    <li>
      <h4>Light experience/familiarity with</h4>
      <ul className={styles.compactList}>
        <li>C/C++</li>
        <li>Java</li>
        <li>MySQL</li>
        <li>MongoDB</li>
        <li>GraphQL</li>
        <li>Python</li>
      </ul>
    </li>
    <li>
      <h4>Future interests</h4>
      <ul className={styles.compactList}>
        <li>React Native</li>
        <li>Swift</li>
        <li>SwiftUI</li>
      </ul>
    </li>
  </ul>
);

const Stint: React.FunctionComponent<{
  title;
  start?;
  end?;
  location;
  organization;
  url;
}> = ({ title, start, end, location, organization, url, children }) => (
  <>
    <div className={styles.topLine}>
      <h3 className={styles.jobTitle}>{title}</h3>
      <div style={{ whiteSpace: 'nowrap' }} className={styles.dates}>
        {start}
        {start && end && <> – </>}
        {end}
      </div>
    </div>
    <div className={styles.organization}>
      {url ? (
        <a href={url} target="_blank">
          {organization}
        </a>
      ) : (
        organization
      )}
    </div>
    <div className={styles.location}>{location}</div>
    {children}
  </>
);

const Resume = () => (
  <article className={styles.resume}>
    <header>
      <h1>Chris Klink</h1>
      <ul>
        {/* <li>
          <a target="_blank" href="https://github.com/dogmar/">
            dogmar@github
          </a>
        </li> */}
        <li>
          <a href="mailto:chris@klink.ink">chris@klink.ink</a>
        </li>
      </ul>
    </header>
    <section className={styles.capabilities}>
      <h2>Capabilities</h2>
      <Capabilities />
    </section>
    <section>
      <h2>Experience</h2>
      <ul className={styles.block}>
        <li>
          <Stint
            title="Senior Designer/Developer"
            organization="Camp + King"
            url="https://camp-king.com"
            location="San Francisco, CA"
            start="2015"
            end="Present"
          >
            <ul className={styles.bulletList}>
              <li>
                Designed and developed promotional websites for clients, meeting
                the needs of accessibility, fast loading times and
                maintainability.
              </li>
              <li>
                Set up and managed agency web server using Ubuntu and Nginx,
                including password protected pages for client reviews
              </li>
              <li>
                Created HTML/JS-based banner ads with a focus on providing rich
                animations in very small file sizes.
              </li>
              <li>
                Created interactive web tools for real estate agents to create
                custom promotional graphics to share with their clients:
                <ul className={styles.compactList}>
                  <li>
                    <a href=" https://www.remaxhustle.com/welcome-mat/select-mat">
                      RE/MAX Welcome Mats
                    </a>
                  </li>
                  <li>
                    <a href="https://www.remaxhustle.com/hustlegraphic">
                      RE/MAX Hustlegraphics
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                Created motion graphics for national ad campaigns, including
                scripting reusable and customizable animation templates in After
                Effects.
              </li>
              <li>
                Designed and illustrated graphics for use in promotions, using
                Adobe Illustrator and Photoshop
              </li>
              <li>
                Created and managed agency website using a combination of
                Contentful back-end, Node/Express server APIs and NextJS front
                end.
                <ul className={styles.compactList}>
                  <li>
                    <a href="https://camp-king.com">camp-king.com</a>
                  </li>
                </ul>
              </li>
            </ul>
          </Stint>
        </li>
        <li>
          <Stint
            title="Senior Interactive Designer"
            organization="FCB"
            location="Seattle, WA + San Francisco, CA"
            start="2008"
            end="2015"
            url="https://www.fcb.com/"
          >
            <ul className={styles.bulletList}>
              <li>
                Designed and developed interactive promotional sites for clients
              </li>
              <li>Created print advertisements for clients</li>
              <li>Designed and animated digital advertisements</li>
            </ul>
          </Stint>
        </li>
        <li>
          <Stint
            title="Embedded Software Engineer"
            organization="The Boeing Company"
            location="Renton, WA"
            start="2003"
            end="2004"
            url="http://www.boeing.com/"
          >
            <ul className={styles.bulletList}>
              <li>
                Programmed bit-level communications software in C++ for aircraft
              </li>
              <li>
                Interpreted military specifications for implementation in
                software
              </li>
            </ul>
          </Stint>
        </li>
      </ul>
    </section>
    <section className={styles.education}>
      <h2>Education</h2>
      <ul className={styles.block}>
        <li>
          <Stint
            title={
              <>
                Associate of Applied Science in{' '}
                <span style={{ whiteSpace: 'nowrap' }}>Graphic Design</span>
              </>
            }
            organization="Seattle Central Creative Academy"
            location="Seattle, WA"
            end="2008"
            url="http://seattlecentralcreativeacademy.com/"
          />
        </li>
        <li>
          <Stint
            title={
              <>
                Bachelor of Science in{' '}
                <span style={{ whiteSpace: 'nowrap' }}>Computer Science</span>
              </>
            }
            organization="Western Washington University"
            location="Bellingham, WA"
            end="2003"
            url="https://www.wwu.edu/"
          />
        </li>
      </ul>
    </section>
  </article>
);

const ResumePage = () => (
  <Wrapper className={styles.resumePage}>
    <SEO title="Resume" />
    <MainMenu />
    <Resume />
  </Wrapper>
);
export default ResumePage;
