// extracted by mini-css-extract-plugin
export const resumePage = "resume-module--resumePage--3ualJ";
export const resume = "resume-module--resume--3eepB";
export const topLine = "resume-module--topLine--2a-0O";
export const bulletList = "resume-module--bulletList--1pCLM";
export const compactList = "resume-module--compactList--NQxo2";
export const capabilities = "resume-module--capabilities--yI4n6";
export const organization = "resume-module--organization--otPO_";
export const location = "resume-module--location--32vO_";
export const schoolLocation = "resume-module--schoolLocation--7ics6";
export const bullets = "resume-module--bullets--25oRf";
export const dates = "resume-module--dates--2ze1z";
export const block = "resume-module--block--1_Bt7";
export const education = "resume-module--education--UbpA_";
export const school = "resume-module--school--1w-mt";